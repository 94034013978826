import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { OverlayLoaderComponent } from './overlay-loader.component';

@Injectable({ providedIn: 'root' })
export class OverlayLoaderService {
	private _spinnerRef = this.createOverlayLoader();

	constructor(private _overlay: Overlay) {}

	showSpinner() {
		this._spinnerRef.attach(new ComponentPortal(OverlayLoaderComponent));
	}

	stopSpinner() {
		this._spinnerRef.detach();
	}

	private createOverlayLoader() {
		return this._overlay.create({
			hasBackdrop: true,
			positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
		});
	}
}
