import { Injectable } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { ITaskTime } from 'src/app/core/models/domain.models';
import { ProtaskState, TaskCondition } from 'src/app/core/models/enumerations';
import { ProtaskSlaComplianceService } from 'src/app/protasks/services/protask-compliance.service';
import { ChartData, ChartInfo, ChartLabels } from '../models/shared.types';

@Injectable({ providedIn: 'root' })
export class ChartService {
	constructor(private _slaService: ProtaskSlaComplianceService) {}

	createChartConfig(chartData: ChartData, chartLabels: ChartLabels, labelColours?: string[]) {
		return {
			type: 'doughnut',
			data: this.getChartConfigData(chartLabels, chartData, labelColours),
			options: this.getChartConfigOptions(chartData.totalCount),
			plugins: [this.getCenterTextInChartPlugin()],
		} as ChartConfiguration;
	}

	createChartInfo(
		id: string,
		chartTitle: string,
		chartData: ChartData,
		chartLabels: ChartLabels,
		tasks: ITaskTime[],
		url: string
	) {
		return {
			id: id,
			chartTitle: chartTitle,
			data: chartData,
			labels: chartLabels,
			taskList: tasks,
			url: url,
		} as ChartInfo;
	}

	getChartDataByState(tasks: ITaskTime[], taskStates: ProtaskState[]) {
		const chartData: ChartData = {
			safeCount: 0,
			warningCount: 0,
			dangerCount: 0,
			totalCount: tasks.length,
		};
		for (let index = 0; index < tasks.length; index++) {
			const task = tasks[index];
			switch (task.state) {
				case taskStates[0]:
					chartData.safeCount++;
					break;
				case taskStates[1]:
					chartData.warningCount++;
					break;
				case taskStates[2]:
					chartData.dangerCount++;
					break;
				default:
					break;
			}
		}
		return chartData;
	}

	getChartDataBySla(filteredTasks: ITaskTime[]) {
		const chartData: ChartData = {
			safeCount: 0,
			warningCount: 0,
			dangerCount: 0,
			totalCount: filteredTasks.length,
		};
		for (let index = 0; index < filteredTasks.length; index++) {
			const sla = this._slaService.getProtaskSlaCompliance(filteredTasks[index]);
			switch (sla?.condition) {
				case TaskCondition.Okay:
					chartData.safeCount++;
					break;
				case TaskCondition.Warning:
					chartData.warningCount++;
					break;
				case TaskCondition.Danger:
					chartData.dangerCount++;
					break;
				default:
					break;
			}
		}
		return chartData;
	}

	private getChartConfigData(
		chartLabels: ChartLabels,
		chartData: ChartData,
		labelColours: string[] = ['#4CAF50', '#FF9800', '#F83B3A']
	) {
		return {
			labels: [chartLabels.first, chartLabels.second, chartLabels.third],
			datasets: [
				{
					datalabels: {
						textAlign: 'end',
					},

					data: [chartData.safeCount, chartData.warningCount, chartData.dangerCount],
					backgroundColor: labelColours,
					hoverOffset: 4,
				},
			],
		};
	}

	private getChartConfigOptions(totalCount: ChartData['totalCount']) {
		return {
			layout: {
				padding: 3,
			},
			cutout: '70%',
			borderWidth: 0,
			responsive: false,
			elements: {
				center: {
					text: totalCount,
					fontStyle: 'Nunito',
					fontWeight: 400,
					minFontSize: 34,
					lineHeight: 40,
				},
			},
			maintainAspectRatio: false,
			plugins: this.getChartOptionPlugins(),
		};
	}

	private getChartOptionPlugins() {
		return {
			legend: {
				display: false,
			},
			tooltip: {
				backgroundColor: 'black',
				bodyFont: {
					family: 'Lato',
					size: 12,
					weight: '400',
				},
				padding: '8',
				caretSize: 0,
				cornerRadius: 3,
				displayColors: false,
			},
		};
	}

	private getDoughnutLabelsLinePlugin() {
		return {
			id: 'doughnutLabelsLine',
			afterDraw(chart, args, options) {
				const {
					ctx,
					chartArea: { top, bottom, left, right, width, height },
				} = chart;

				chart.data.datasets.forEach((dataset, i) => {
					chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
						const { x, y } = datapoint.tooltipPosition();

						// Draw the line
						const halfWidth = width / 2;
						const halfHeight = height / 2;

						const xLine = x >= halfWidth ? x + 2 : x - 2;
						const yLine = y >= halfHeight ? y + 25 : y - 25;
						const extraLine = x >= halfWidth ? 2 : 0;

						ctx.beginPath();
						ctx.moveTo(x, y);
						ctx.lineTo(xLine, yLine);
						ctx.lineTo(xLine + extraLine, yLine);
						ctx.strokeStyle = 'black';
						ctx.stroke();

						// Display the text value
						const labelWidth = ctx.measureText(chart.data.labels[index]).width;
						ctx.font = '14px Nunito';

						// let t: CanvasRenderingContext2D;
						// t.textBaseline = 'hanging';

						// Control the position
						const textXPosition = x >= halfWidth ? 'left' : 'right';
						const plusFivePx = x >= halfWidth ? 5 : -5;
						ctx.textAlign = textXPosition;
						ctx.textBaseline = 'middle';
						ctx.fillStyle = dataset.backgroundColor[index];
						ctx.fillText(dataset.data[index], xLine + extraLine + plusFivePx, yLine);
					});
				});
			},
		};
	}

	private getCenterTextInChartPlugin() {
		return {
			id: 'counter',
			beforeDraw(chart, args, options) {
				if (chart.config.options.elements.center) {
					// Get ctx from string
					const ctx = chart.ctx;

					// Get options from the center object in options
					const centerConfig = chart.config.options.elements.center;
					const fontStyle = centerConfig.fontStyle || 'Arial';
					const txt = centerConfig.text;
					const color = centerConfig.color || '#000';
					const maxFontSize = centerConfig.maxFontSize || 75;
					const sidePadding = centerConfig.sidePadding || 20;
					const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
					// Start with a base font of 30px
					ctx.font = '30px ' + fontStyle;

					// Get the width of the string and also the width of the element minus 10 to give it 5px side padding
					const stringWidth = ctx.measureText(txt).width;
					const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

					// Find out how much the font can grow in width.
					const widthRatio = elementWidth / stringWidth;
					const newFontSize = Math.floor(30 * widthRatio);
					const elementHeight = chart.innerRadius * 2;

					// Pick a new font size so it will not be larger than the height of label.
					let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
					let minFontSize = centerConfig.minFontSize;
					const lineHeight = centerConfig.lineHeight || 25;
					let wrapText = false;

					if (minFontSize === undefined) {
						minFontSize = 20;
					}

					if (minFontSize && fontSizeToUse < minFontSize) {
						fontSizeToUse = minFontSize;
						wrapText = true;
					}

					// Set font settings to draw it correctly.
					ctx.textAlign = 'center';
					ctx.textBaseline = 'middle';
					const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
					let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
					ctx.font = fontSizeToUse + 'px ' + fontStyle;
					ctx.fillStyle = color;

					if (!wrapText) {
						ctx.fillText(txt, centerX, centerY);
						return;
					}

					const words = txt.split(' ');
					var line = '';
					var lines = [];

					// Break words up into multiple lines if necessary
					for (let n = 0; n < words.length; n++) {
						var testLine = line + words[n] + ' ';
						var metrics = ctx.measureText(testLine);
						var testWidth = metrics.width;
						if (testWidth > elementWidth && n > 0) {
							lines.push(line);
							line = words[n] + ' ';
						} else {
							line = testLine;
						}
					}

					// Move the center up depending on line height and number of lines
					centerY -= (lines.length / 2) * lineHeight;

					for (var n = 0; n < lines.length; n++) {
						ctx.fillText(lines[n], centerX, centerY);
						centerY += lineHeight;
					}
					//Draw text in center
					ctx.fillText(line, centerX, centerY);
				}
			},
		};
	}
}
