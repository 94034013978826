import { Injectable } from '@angular/core';
import { Protask } from '../../models/domain.models';
import { IProtaskDetail2Dto } from '../../models/dto.models';
import { DataSyncAction, OperationOutcome, ProtaskOperation, ProtaskState } from '../../models/enumerations';
import { IPushModelProcessor, IPushModel } from '../../models/push.model';
import { TaskStoreService } from '../../../protasks/services/task-store.service';
import { HelperService } from '../../../utilities/services/helper.service';
import { DomainModelFactoryService } from '../domain-model-factory.service';
import { SnackbarService } from '../snackbar.service';
import { TaskChangeStoreService } from './task-change-store.service';
import { MessagingCenterService } from '../messaging-center.service';

@Injectable({ providedIn: 'root' })
export class ReceiveNewStateChangeService implements IPushModelProcessor {
	constructor(
		private _messageCenter: MessagingCenterService,
		private _taskBuilder: DomainModelFactoryService,
		private _taskStoreService: TaskStoreService,
		private _helperService: HelperService,
		private _taskChangeStore: TaskChangeStoreService,
		private _snackbarService: SnackbarService
	) {}

	processPushModel(pushModel: IPushModel): void {
		console.log('STATE CHANGE', pushModel);
		const protask = this.mapDtoToProtask(pushModel.payload);
		const dataOperationOutcome = this.performDatabaseManagment(protask, pushModel.action);
		const isRelatedProtaskDetailPageOpen = this.isProtaskDetailPageOpenForTheRelatedProtask(protask.id);

		if (this.isNotificationNeeded(pushModel.action, dataOperationOutcome)) {
			if (protask.state !== ProtaskState.Closed && !isRelatedProtaskDetailPageOpen) {
				this._taskChangeStore.addKeyToChangeSet(protask.id);
			}
			this.notifyUser(protask, pushModel.operation);
		}
	}

	private isNotificationNeeded(action: DataSyncAction, dataOperationOutcome: OperationOutcome): boolean {
		if (dataOperationOutcome !== OperationOutcome.NoChange) {
			return this._helperService.shouldNotifyUser(action);
		}
		return false;
	}

	private isProtaskDetailPageOpenForTheRelatedProtask(protaskId: number) {
		return this._helperService.checkIfUserIsOnDetailsPage(protaskId);
	}

	private mapDtoToProtask(protaskDto: IProtaskDetail2Dto): Protask {
		return this._taskBuilder.buildProtask(protaskDto);
	}

	private performDatabaseManagment(protask: Protask, action: DataSyncAction): OperationOutcome {
		return this._taskStoreService.mergeTaskStateChange(protask, action);
	}

	private notifyUser(newTask: Protask, protaskOperation: ProtaskOperation): void {
		this._snackbarService.notifyOnTaskUpdate(newTask, protaskOperation);
		this._messageCenter.publishNewRefreshEvent();
	}
}

// Shopping list:

// 1. Convert task to a Protask - Done

// 2. Notify the user with the snackbar service

// 3. Update flag chip

// 4. Update red dot

// 5. Replace current task in task store with the new one
