import { Injectable } from '@angular/core';
import { NavigationStart, ResolveStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { OverlayLoaderService } from '../layout/overlay-loader/overlay-loader.service';

@Injectable({ providedIn: 'root' })
export class LoadingService {
	private _isLoading$ = new BehaviorSubject<boolean>(false);

	constructor(private _overlayLoader: OverlayLoaderService) {}

	get isLoading$() {
		return this._isLoading$;
	}

	setLoadingState(loadingState: boolean) {
		this._isLoading$.next(loadingState);
	}

	handleRouteEvent(event) {
		if (event instanceof NavigationStart || event instanceof ResolveStart) {
			this._overlayLoader.showSpinner();
		} else {
			this._overlayLoader.stopSpinner();
		}
	}
}
