<mat-card class="grid-card">
	<div class="flex-row" style="align-items: center">
		<h6>{{ totalNumberOfTasks }} Tasks</h6>
		<span class="spacer"></span>
		<mat-chip-list>
			<mat-chip
				(click)="filterTasksByState(protaskState.Open)"
				[ngClass]="activeFilter === protaskState.Open ? 'active-filter' : 'default-filter'"
			>
				{{ numberOfOpenTasks }} Open Tasks
			</mat-chip>
			<mat-chip
				(click)="filterTasksByState(protaskState.InProgress)"
				[ngClass]="activeFilter === protaskState.InProgress ? 'active-filter' : 'default-filter'"
			>
				{{ numberOfInProgressTasks }} In Progress Tasks
			</mat-chip>
		</mat-chip-list>
		<span style="margin-left: 16px" class="caption text-dark-2">{{ numberOfCompleteTasks }} Completed Tasks</span>
	</div>
	<mat-card-content>
		<div class="flex-column" *ngFor="let chart of chartInfo; trackBy: trackBy">
			<h6 style="text-align: center">{{ chart.chartTitle }}</h6>
			<canvas
				id="{{ chart.id }}"
				style="letter-spacing: 0.4px"
				height="200"
				[ngClass]="{ pointer: !!chart?.url }"
				(click)="navigate(chart?.url)"
			></canvas>
			<div class="legend-box">
				<!-- Tally & Label 1 -->
				<div class="flex-column">
					<span class="subtitle-2" style="text-align: center">{{ chart.data.safeCount }}</span>
					<span class="label-row caption text-dark-2">
						<div class="safe-dot"></div>
						{{ chart.labels.first }}
					</span>
				</div>

				<!-- Tally & Label 2 -->
				<div class="flex-column">
					<span class="subtitle-2" style="text-align: center">{{ chart.data.warningCount }}</span>
					<span class="label-row caption text-dark-2">
						<div class="warning-dot"></div>
						{{ chart.labels.second }}
					</span>
				</div>

				<!-- Tally & Label 3 -->
				<div class="flex-column">
					<span class="subtitle-2" style="text-align: center">{{ chart.data.dangerCount }}</span>
					<span class="label-row caption text-dark-2">
						<div class="danger-dot"></div>
						{{ chart.labels.third }}
					</span>
				</div>
			</div>
		</div>
	</mat-card-content>
</mat-card>
