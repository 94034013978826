<!-- Main Routes -->
<section class="routes-box">
	<mat-nav-list>
		<!-- Home -->
		<a
			mat-list-item
			routerLink="home"
			routerLinkActive="active-link"
			matTooltip="{{ !isSidenavOpen ? 'Home' : '' }}"
			matTooltipPosition="right"
		>
			<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">home</mat-icon>
			<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Home</span>
		</a>
		<!-- Tasks -->
		<a
			mat-list-item
			routerLink="tasks"
			routerLinkActive="active-link"
			matTooltip="{{ !isSidenavOpen ? 'Tasks' : '' }}"
			matTooltipPosition="right"
		>
			<mat-icon
				mat-list-icon
				[matBadge]="!!numberOfChanges && !isSidenavOpen ? numberOfChanges : null"
				[style.padding-left.px]="isSidenavOpen ? 8 : 4"
				>list</mat-icon
			>
			<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Tasks</span>
			<app-changes-indicator
				*ngIf="!!numberOfChanges && isSidenavOpen"
				[numberOfChanges]="numberOfChanges"
			></app-changes-indicator>
		</a>
	</mat-nav-list>
</section>

<!-- Reports & Analytics -->
<div *ngIf="userProfile$ | async as userProfile">
	<section
		class="routes-box"
		*ngIf="userProfile.allowReport2 || userProfile.allowReport3 || userProfile.allowReport4"
	>
		<div class="body-2 box-title" *ngIf="isSidenavOpen">Reports & Analytics</div>
		<mat-nav-list>
			<!-- Activity -->
			<a
				*ngIf="userProfile.allowReport2"
				mat-list-item
				routerLink="reports/activity"
				routerLinkActive="active-link"
				matTooltip="{{ !isSidenavOpen ? 'Activity' : '' }}"
				matTooltipPosition="right"
			>
				<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">pie_charts</mat-icon>
				<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Activity</span>
			</a>
			<!-- Service Rating -->
			<a
				*ngIf="userProfile.allowReport3"
				mat-list-item
				routerLink="reports/service-rating"
				routerLinkActive="active-link"
				matTooltip="{{ !isSidenavOpen ? 'Service Rating' : '' }}"
				matTooltipPosition="right"
			>
				<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">location_city</mat-icon>
				<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Service Rating</span>
			</a>
			<!-- Geofence Validation -->
			<a
				*ngIf="userProfile.allowReport4"
				mat-list-item
				routerLink="reports/geofence-validation"
				routerLinkActive="active-link"
				matTooltip="{{ !isSidenavOpen ? 'Geofence Validation' : '' }}"
				matTooltipPosition="right"
			>
				<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">my_location</mat-icon>
				<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Geofence Validation</span>
			</a>
		</mat-nav-list>
	</section>
</div>

<!-- User Guides -->
<section class="routes-box" style="box-shadow: none">
	<div class="body-2 box-title" *ngIf="isSidenavOpen">Advanced Analytics</div>
	<mat-nav-list>
		<!-- Mirlin Essentials -->
		<a
			mat-list-item
			routerLink="views/mirlinEssentials"
			routerLinkActive="active-link"
			matTooltip="{{ !isSidenavOpen ? 'Mirlin Essentials' : '' }}"
			matTooltipPosition="right"
		>
			<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">explicit</mat-icon>
			<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Mirlin Essentials</span>
		</a>
		<!-- Mirlin Pro -->
		<a
			mat-list-item
			routerLink="views/mirlinPro"
			routerLinkActive="active-link"
			matTooltip="{{ !isSidenavOpen ? 'Mirlin Pro' : '' }}"
			matTooltipPosition="right"
		>
			<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">local_parking</mat-icon>
			<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Mirlin Pro</span>
		</a>
		<!-- Asset Life Cycle -->
		<a
			mat-list-item
			routerLink="views/assetLifeCycle"
			routerLinkActive="active-link"
			matTooltip="{{ !isSidenavOpen ? 'Asset Life Cycle' : '' }}"
			matTooltipPosition="right"
		>
			<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">data_saver_off</mat-icon>
			<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Asset Life Cycle</span>
		</a>
		<!-- Equipment Options -->
		<a
			mat-list-item
			routerLink="views/equipmentOptions"
			routerLinkActive="active-link"
			matTooltip="{{ !isSidenavOpen ? 'Equipment Options' : '' }}"
			matTooltipPosition="right"
		>
			<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">web</mat-icon>
			<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Equipment Options</span>
		</a>
		<!-- Original Equipment Manufacturer -->
		<a
			mat-list-item
			routerLink="views/oemReport"
			routerLinkActive="active-link"
			matTooltip="{{ !isSidenavOpen ? 'Original Equipment Manufacturer' : '' }}"
			matTooltipPosition="right"
		>
			<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">work</mat-icon>
			<span class="subtitle-2" matLine *ngIf="isSidenavOpen">OEM</span>
		</a>
		<!-- Fleet Profile -->
		<a
			mat-list-item
			routerLink="views/fleetProfile"
			routerLinkActive="active-link"
			matTooltip="{{ !isSidenavOpen ? 'Mirlin Fleet Profile' : '' }}"
			matTooltipPosition="right"
		>
			<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">work</mat-icon>
			<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Mirlin Fleet Profile</span>
		</a>
		<!-- Forecast -->
		<a
			mat-list-item
			routerLink="views/forecast"
			routerLinkActive="active-link"
			matTooltip="{{ !isSidenavOpen ? 'Forecast' : '' }}"
			matTooltipPosition="right"
		>
			<mat-icon mat-list-icon [style.padding-left.px]="isSidenavOpen ? 8 : 4">work</mat-icon>
			<span class="subtitle-2" matLine *ngIf="isSidenavOpen">Forecast</span>
		</a>
	</mat-nav-list>
</section>
