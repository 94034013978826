import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';

import { Protask } from '../core/models/domain.models';
import { ProtaskUserRole } from '../core/models/enumerations';
import { GridLayout } from '../core/types/grid-layout.type';
import { Article } from '../shared/news-center/models/news.models';
import { ChartInfo } from '../shared/models/shared.types';

import { TaskConductorService } from '../protasks/services/task-conductor.service';
import { ViewportService } from '../utilities/layout/viewport.service';
import { NewsFilterSections } from '../shared/news-center/models/news-center.enums';
import { NewsHttpService } from '../shared/news-center/news-http.service';
import { finalize } from 'rxjs/operators';
import { ChartService } from '../shared/chart/chart.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
	gridLayoutSizes: GridLayout;
	chartInfo: ChartInfo[] = [];
	announcementsPreviews: Article[] = [];
	announcementsAreLoading = false;
	updatesPreviews: Article[] = [];
	updatesAreLoading = false;

	private _subSink = new SubSink();

	constructor(
		private _chartService: ChartService,
		private _newsHttpService: NewsHttpService,
		private _viewportService: ViewportService,
		private _taskConductor: TaskConductorService
	) {
		this.downloadNews();
		this.downloadUpdates();
	}

	ngOnInit(): void {
		this.observeViewport();
		this.observeTaskStore();
	}

	ngOnDestroy(): void {
		this._subSink.unsubscribe();
	}

	private downloadNews() {
		this.announcementsAreLoading = true;
		const newsId = NewsFilterSections.Announcements;
		this._subSink.sink = this._newsHttpService
			.downloadSelectArticlePreviews(newsId, 3)
			.pipe(finalize(() => (this.announcementsAreLoading = false)))
			.subscribe((newsData) => (this.announcementsPreviews = newsData));
	}

	private downloadUpdates() {
		this.updatesAreLoading = true;
		const updatesId = NewsFilterSections.Updates;
		this._subSink.sink = this._newsHttpService
			.downloadSelectArticlePreviews(updatesId, 3)
			.pipe(finalize(() => (this.updatesAreLoading = false)))
			.subscribe((updatesData) => (this.updatesPreviews = updatesData));
	}

	private observeViewport() {
		this._subSink.sink = this._viewportService
			.observeViewportSize()
			.subscribe((screenSize) => (this.gridLayoutSizes = screenSize));
	}

	private observeTaskStore() {
		this._subSink.sink = this._taskConductor.getAllTasksInStore$().subscribe((tasks) => {
			const submittedChartInfo = this.getSubmittedTasksChartInfo(tasks);
			const receivedChartInfo = this.getReceivedTasksChartInfo(tasks);
			this.chartInfo = [submittedChartInfo, receivedChartInfo];
		});
	}

	private getReceivedTasksChartInfo(tasks: Protask[]) {
		const receivedTasks = [...tasks].filter(
			(task) =>
				task.userRole === ProtaskUserRole.Provider || task.userRole === ProtaskUserRole.ServiceChannelProviders
		);
		const receivedChartInfo: ChartInfo = {
			id: 'receivedChart',
			chartTitle: 'Received Tasks',
			data: this._chartService.getChartDataBySla(receivedTasks),
			labels: { first: 'On Time', second: 'Warning', third: 'Overdue' },
			taskList: receivedTasks,
			url: 'tasks/received',
		};
		return receivedChartInfo;
	}

	private getSubmittedTasksChartInfo(tasks: Protask[]) {
		const submittedTasks = [...tasks].filter(
			(task) =>
				task.userRole === ProtaskUserRole.Submitter ||
				task.userRole === ProtaskUserRole.ServiceChannelSubmitters
		);
		const submittedChartInfo: ChartInfo = {
			id: 'submittedChart',
			chartTitle: 'Submitted Tasks',
			data: this._chartService.getChartDataBySla(submittedTasks),
			labels: { first: 'On Time', second: 'Warning', third: 'Overdue' },
			taskList: submittedTasks,
			url: 'tasks/submitted',
		};
		return submittedChartInfo;
	}
}
