import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { ITaskTime, Protask } from 'src/app/core/models/domain.models';
import { ProtaskState } from 'src/app/core/models/enumerations';
import { ChartInfo } from '../../models/shared.types';
import { ChartService } from '../chart.service';

@Component({
	selector: 'app-iterable-chart-card',
	templateUrl: './iterable-chart-card.component.html',
	styleUrls: ['./iterable-chart-card.component.scss'],
})
export class IterableChartCardComponent implements OnChanges, OnInit, OnInit, AfterViewInit {
	@Input() chartInfo: ChartInfo[];

	totalNumberOfTasks = 0;
	numberOfCompleteTasks = 0;
	numberOfOpenTasks = 0;
	numberOfInProgressTasks = 0;
	protaskState = ProtaskState;
	activeFilter = ProtaskState.Open;

	private _chartReferences: Chart<any>[] = [];

	constructor(private _router: Router, private _changeRef: ChangeDetectorRef, private _chartService: ChartService) {}

	ngOnChanges(): void {
		if (!!this._chartReferences.length) {
			this.chartInfo.forEach((chart) => this.updateChartTallies(chart));
			this.filterTasksByState(this.activeFilter);
		}
	}

	ngOnInit(): void {
		Chart.register(...registerables);
	}

	ngAfterViewInit(): void {
		this.totalNumberOfTasks = 0;
		this.chartInfo.forEach((chart) => {
			const config = this._chartService.createChartConfig(chart.data, chart.labels);
			const chartRef = new Chart(chart.id, config);
			this._chartReferences.push(chartRef);
			this.updateChartTallies(chart);
		});
		this.filterTasksByState(this.activeFilter);
	}

	trackBy(index: number, chart: ChartInfo) {
		return chart.id;
	}

	navigate(chartUrl: string) {
		if (!!chartUrl) {
			this._router.navigateByUrl(chartUrl);
		}
	}

	filterTasksByState(state: ProtaskState) {
		this.activeFilter = state;
		this.chartInfo.forEach((chart) => {
			const filteredTasks = [...chart.taskList].filter((task) => task.state === state);
			chart.data = this._chartService.getChartDataBySla(filteredTasks);
		});
		this.updateChartData();
	}

	private updateChartTallies(chart: ChartInfo) {
		this.totalNumberOfTasks += chart.data.totalCount;
		this.numberOfCompleteTasks += this.getNumberOfTasksByState(chart.taskList, ProtaskState.Complete);
		this.numberOfInProgressTasks += this.getNumberOfTasksByState(chart.taskList, ProtaskState.InProgress);
		this.numberOfOpenTasks += this.getNumberOfTasksByState(chart.taskList, ProtaskState.Open);
	}

	private getNumberOfTasksByState(taskList: ITaskTime[], stateToFilterBy: ProtaskState) {
		return [...taskList].filter((task) => task.state === stateToFilterBy).length;
	}

	private updateChartData() {
		this.totalNumberOfTasks = 0;
		this.chartInfo.forEach((chart, index) => {
			this.totalNumberOfTasks += chart.data.totalCount;
			this._changeRef.detectChanges();
			this._chartReferences[index].data.datasets[0].data[0] = chart.data.safeCount;
			this._chartReferences[index].data.datasets[0].data[1] = chart.data.warningCount;
			this._chartReferences[index].data.datasets[0].data[2] = chart.data.dangerCount;
			this._chartReferences[index].config.options.elements.center.text = chart.data.totalCount;
			this._chartReferences[index].update();
		});
	}
}
