import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TitleService {
	constructor(private _router: Router) {}

	registerForNewPageTitles() {
		return this._router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => {
				let route = this._router.routerState.root;
				let pageTitle = '';
				while (route!.firstChild) {
					route = route.firstChild;
				}
				if (route.snapshot.data['title']) {
					pageTitle = route!.snapshot.data['title'];
				}
				return pageTitle;
			})
		);
	}
}
