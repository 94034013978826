<div *ngIf="activeAccount$ | async">
	<!-- Primary Toolbar -->
	<mat-toolbar class="mirlin-purple toolbar">
		<button mat-icon-button (click)="toggleSidenav()">
			<mat-icon>menu</mat-icon>
		</button>

		<!-- Mirlin Logo -->
		<img class="logo" src="../../../assets/img/MirlinLogo.svg" alt="logo" />

		<span class="spacer"></span>

		<!-- Buttons -->
		<section>
			<button mat-icon-button style="margin-right: 8px" routerLink="help">
				<mat-icon class="gray">help</mat-icon>
			</button>

			<button mat-icon-button routerLink="settings">
				<mat-icon class="gray">settings</mat-icon>
			</button>

			<!-- <button mat-flat-button>
				<span class="button upgrade">Upgrade</span>
			</button> -->
		</section>

		<!-- Profile Info -->
		<div class="profile-box">
			<app-user-profile-picture
				class="avatar"
				[matMenuTriggerFor]="menu"
				[profilePicUrl]="profilePicUrl"
				[name]="name"
				*ngIf="profilePicUrl || name"
			>
			</app-user-profile-picture>
			<mat-menu #menu="matMenu">
				<button mat-menu-item (click)="logout()">
					<span>Logout</span>
				</button>
			</mat-menu>
		</div>
	</mat-toolbar>

	<!-- Sidenav  -->
	<mat-sidenav-container>
		<mat-sidenav
			class="thin-scrollbar"
			#sidenav
			mode="side"
			fixedInViewport="true"
			fixedTopGap="64"
			opened
			[ngClass]="isSidenavOpen ? 'open-sidenav' : 'mini-sidenav'"
		>
			<!-- Company Logo Box -->
			<section>
				<section class="sidenav-logo" *ngIf="isSidenavOpen">
					<div class="image-box">
						<img class="image" src="../assets/img/goright.png" alt="Company Logo" />
					</div>
				</section>

				<section [ngClass]="isSidenavOpen ? 'button-box' : 'mini-button-box'">
					<button
						color="primary"
						mat-flat-button
						routerLink="create"
						matTooltip="{{ !isSidenavOpen ? 'Create Task' : '' }}"
						matTooltipPosition="right"
						[disabled]="pageTitle === 'Create Task'"
					>
						<mat-icon>add</mat-icon>
						<span class="button" *ngIf="isSidenavOpen">Create Task</span>
					</button>
				</section>
				<mat-divider></mat-divider>
			</section>

			<!-- Routes -->
			<app-sidenav-menu [isSidenavOpen]="isSidenavOpen" [currentPageTitle]="pageTitle"></app-sidenav-menu>

			<!-- Legal Box -->
			<div *ngIf="isSidenavOpen" class="bottom-box {{ absoluteOrRelativePosition }}">
				<div style="display: flex; align-items: center">
					<span class="powered-by-mirlin">
						<span class="text-style-1">© Mirlin </span>
						<span class="caption text-dark-2">ver {{ version }}</span>
					</span>
					<span class="caption terms-legal">
						Terms
						<span class="legal-text"> | </span>
						Legal
					</span>
				</div>
			</div>
		</mat-sidenav>

		<!-- Content -->
		<mat-sidenav-content class="content-settings" [style.margin-left.px]="!isSidenavOpen ? 56 : 248">
			<!-- <div *ngIf="isLoading$ | async" class="loading-bar">
				<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
			</div> -->
			<router-outlet></router-outlet>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
