<mat-card class="grid-card">
	<mat-card-title>{{ title }}</mat-card-title>
	<mat-card-content *ngIf="!!articles?.length">
		<div *ngFor="let article of articles" class="detail-card" matRipple>
			<!-- Linked Article -->
			<a *ngIf="article.ct === 2; else notLink" href="{{ article.cu }}" class="news-box">
				<img src="{{ !!article?.ni ? article.ni : '../../../assets/img/mirlin-news.png' }}" />
				<div class="flex-column">
					<span class="caption" style="margin-bottom: 4px">
						{{ article?.pb }} {{ !!article?.pb ? "-" : null }}
						{{ article?.po | date: "MMMM d y, h:mm a" }}
					</span>
					<h6>{{ article.ti }}</h6>
					<span class="subtitle-1 gray">{{ article?.su }}</span>
				</div>
			</a>

			<!-- Text Article -->
			<ng-template #notLink>
				<div (click)="navigateToArticle(article)" class="news-box">
					<img src="{{ !!article?.ni ? article.ni : '../../../assets/img/mirlin-news.png' }}" />
					<div class="flex-column">
						<span class="caption" style="margin-bottom: 4px">
							{{ article?.pb }} {{ !!article?.pb ? "-" : null }}
							{{ article?.po | date: "MMMM d y, h:mm a" }}
						</span>
						<h6>{{ article.ti }}</h6>
						<span class="subtitle-1 gray">{{ article?.su }}</span>
					</div>
				</div>
			</ng-template>
		</div>
		<mat-grid-tile-footer class="view-all">
			<button class="button" color="primary" mat-button (click)="navigateToNews()">
				<span style="padding-left: 12px; padding-right: 8px">View All</span>
				<mat-icon style="padding-right: 8px"> arrow_forward </mat-icon>
			</button>
		</mat-grid-tile-footer>
	</mat-card-content>

	<!-- No Content -->
	<mat-card-content *ngIf="!articles?.length && !showLoading">
		<h6>No data available</h6>
	</mat-card-content>

	<!-- Show Loading -->
	<mat-card-content *ngIf="showLoading" class="center">
		<mat-spinner [diameter]="50"></mat-spinner>
	</mat-card-content>
</mat-card>
